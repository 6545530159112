<template>
    <HeaderComponent/>
    <section class="w-screen h-[calc(100vh-70px)] relative top-[70px] flex flex-col justify-center items-center">
        <div class="h-full w-full relative">
            <div class="w-full h-full object-cover">
                <img src="../assets/images/Caille/R.jpeg" alt="" class="absolute w-full h-full object-cover bg-center">
            </div>
            <div class="fixed md:flex justify-center items-center text-black md:w-[95%] h-[100vh] w-full md:max-w-[800px] left-[50%] translate-x-[-50%] bottom-[0px] md:max-h-[95%] md:h-[800px]">
                <div id="parent" class=" w-full h-full p-[20px] py-[100px] md:px-[50px] overflow-y-auto md:py-[50px] bg-gray-100">
                    <form @submit.prevent="creerReservation" class="">
                        <h1 class="my-[10px] mt-[40px] md:mt-[10px] text-center font-bold">Réserver maintenant</h1>
                        <div class="w-full relative flex flex-col p-[10px]">
                            <input type="text" name="" v-model="nom" id="nom" required placeholder="Nom complet" class="input border border-gray-200 focus:border-none focus:shadow-5xl w-full p-[8px] outline-none rounded-[5px]">
                        </div>
                        <div class="w-full relative flex flex-col p-[10px]">
                            <input type="text" name="" v-model="numero" id="num" required placeholder="Numéro momo" class="input border border-gray-200 focus:border-none font-semibold focus:shadow-5xl w-full p-[10px] pl-[50px] text-sm outline-none rounded-[5px]">
                            <label for="" class="absolute top-[21px] left-[20px] text-sm font-bold text-gray-800 ">+229</label>
                        </div>
                        <div class="w-full relative flex flex-col p-[10px]">
                            <input type="text" name="" v-model="email" id="email" required placeholder="Email" class="input border border-gray-200 focus:border-none focus:shadow-5xl w-full p-[8px] outline-none rounded-[5px]">
                        </div>
                        <div class="w-full relative flex justify-between">
                            <div class="flex relative flex-col w-[49%] p-[10px]">
                                <input type="date" name="" v-model="date" required id="date" class="input border border-gray-200 focus:border-none focus:shadow-5xl w-full p-[8px] outline-none rounded-[5px]">
                            </div>
                            <div class="flex relative flex-col w-[49%] p-[10px]">
                                <input type="text" name="" v-model="heure" required id="heure" placeholder="Heure" class="input border border-gray-200 focus:border-none focus:shadow-5xl w-full p-[8px] outline-none rounded-[5px]">
                            </div>
                        </div>
                        <div class="w-full relative flex flex-col p-[10px]">
                            <input type="text" name="" v-model="place" required id="place" placeholder="Nombre de personne" class="input border border-gray-200 focus:border-none focus:shadow-5xl w-full p-[8px] outline-none rounded-[5px]">
                        </div>
                        <div class="w-full relative flex flex-col p-[10px]">
                            <input type="text" name="" value="1000FCFA" readonly id="prix" class="input border  cursor-pointer border-gray-200 w-full p-[10px] outline-none rounded-[5px]">
                        </div>
                        <div class="w-full justify-center items-center relative flex p-[10px]">
                            <button type="submit" :disabled="loading" name="" value="Réserver" class="input border border-white bg-white duration-300 overflow-hidden ease-in-out w-auto hover:border-gray-100  hover:bg-gray-100 text-orange-500 py-[8px] px-[20px] shadow-4xl outline-none rounded-[5px]">
                                <div v-if="loading" >
                                    <div class="newtons-cradle">
                                        <div class="newtons-cradle__dot"></div>
                                        <div class="newtons-cradle__dot"></div>
                                        <div class="newtons-cradle__dot"></div>
                                        <div class="newtons-cradle__dot"></div>
                                    </div>
                                </div>
                                <span v-else>Réserver</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <section id="pap1" class="w-screen hidden h-screen z-[40] justify-center items-center bg-gray-700 fixed top-0 left-0 right-0 bottom-0">
      <div class="w-[90%] h-[380px] fixed max-w-[300px] flex flex-col justify-center items-center rounded-[10px] p-[20px] top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] z-30">
          
        <div class="loader"></div>
        <div class="my-[10px] mt-[100px] text-white font-bold text-2xl text-center">
            Paiement en attente 
        </div>
      </div>
    </section>
    <section id="pap" class="w-screen hidden h-screen z-[100] bg-gray-700 fixed top-0 left-0 right-0 bottom-0">
      <div v-if="reservationData" id="appa" class="w-[90%] h-[380px] fixed max-w-[300px] rounded-[10px] p-[20px] bg-white bottom-[100px] left-[50%] translate-x-[-50%] z-30 shadow-4xl">
        <div class="absolute top-[-30px] left-[50%] translate-x-[-50%] w-[60px] h-[60px] rounded-[50%] bg-white p-[5px]">
          <div class="w-full h-full relative rounded-[50%] bg-green-600 flex justify-center text-2xl text-white items-center"><i class="fa-solid fa-check"></i></div>
        </div>
        <div id="creu" class="py-[20px] relative border-b border-dashed border-gray-500 flex flex-col justify-center items-center">
          <h1 class="text-xl font-bold text-black">Thank you !</h1>
          <p class="text-xs text-gray-500">Votre réservation a été éffectué avec succès</p>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-between items-center text-xs uppercase text-gray-500 ">
            <span>DATE</span>
            <span>Time</span>
          </div>
          <div class="w-full flex justify-between items-center text-sm font-bold text-black ">
            <span>{{ reservationData._value.date }}</span>
            <span>{{ reservationData._value.heure }}</span>
          </div>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-center items-start flex-col">
            <span class="text-xs uppercase text-gray-500 ">From</span>
            <span class="text-sm font-bold text-black">{{ reservationData._value.nom }}</span>
            <span class="text-xs font-bold text-gray-700">{{ reservationData._value.email }}</span>
          </div>
        </div>
        <div class="flex w-full justify-between items-center py-[10px]">
          <div class="w-full flex justify-center flex-col items-start text-xs uppercase text-gray-500 ">
            <span class="text-xs uppercase text-gray-500 ">Montant</span>
            <span class="text-sm font-bold text-black">FCFA 1000.00</span>
          </div>
          <div class="w-full flex justify-center flex-col items-end text-xs uppercase text-gray-500 ">
            <div class="text-xs uppercase text-gray-400 border-gray-400 border rounded-[5px] p-[5px]">
              Completé
            </div>
          </div>
        </div>
        <div class="mt-[15px] p-[10px] w-full rounded-[10px] bg-gray-100 flex justify-start items-center">
          <div class="flex justify-center items-center">
            <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center shadow-6xl items-center relative overflow-hidden">
              <img src="../assets/images/Caille/MTN Momo.png" alt="" class="w-full h-full last:">
            </div>
            <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center ml-[5px] shadow-6xl items-center relative overflow-hidden">
              <img src="../assets/images/Caille/Moov Money.png" alt="" class="w-full h-full last:">
            </div>
          </div>
          <div class="mx-[10px] flex flex-col items-start justify-center">
            <span class="text-sm font-bold text-black">Mobile Money</span>
            <span class="text-xs text-gray-500 ">Envoyer par le numero {{ reservationData._value.numero }}</span>
          </div>
        </div>
        <div class="absolute bottom-[-70px] left-[50%] translate-x-[-50%] w-[35px] h-[35px] rounded-[50%] bg-gray-600 p-[5px]">
          <div class="w-full h-full relative rounded-[50%] cursor-pointer flex justify-center text-2xl text-white items-center" @click="afterReservation()"><i class="fa-solid fa-xmark"></i></div>
        </div>
      </div>
    </section>

</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { ref, onMounted } from 'vue';
const reservationData = ref(null);
const loading = ref(false);

const pageTitle = ref("Réservez une table - Caille d'Or")
onMounted(() => {
  document.title = pageTitle.value;
});

const nom = ref('');
const numero = ref('');
const date = ref('');
const heure = ref('');
const place = ref('');
const email = ref('')

const creerReservation = async () => {
  try {
    loading.value = true;
    const response = await axios.post('/paiement/reservation', {
      nom: nom.value,
      numero: numero.value,
      email: email.value,
      date: date.value,
      heure: heure.value,
      place: place.value
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    reservationData.value = {
      nom: nom.value,
      numero: numero.value,
      email: email.value,
      date: date.value,
      heure: heure.value,
      place: place.value
    };

    localStorage.setItem('reservationData', JSON.stringify(reservationData));  

    console.log('Réservation créée avec succès:', response.data);
    window.location.href = response.data.redirectUrl;
  } catch (error) {
    console.error('Erreur lors du paiement:', error);
    
  } finally {
        loading.value = false; 
    }
};

const router = useRouter()

function goToHomePage() {
    router.push("/")
}

function afterReservation() {
    localStorage.removeItem('reservationData');
    router.push("/")
}


const sendMessage = async () => {
  try {
    const reservationData = JSON.parse(localStorage.getItem('reservationData'));

    const response = await axios.post('/reservations', {
      nom: reservationData._value.nom,
      numero: reservationData._value.numero,
      email: reservationData._value.email,
      date: reservationData._value.date,
      heure: reservationData._value.heure,
      place: reservationData._value.place
    });
    showNotification();

  } catch (error) {
    console.error('Erreur lors de l\'ajout de la réservation:', error);
  }
};

const showNotification = () => {
  document.getElementById('pap').style.display = 'block';
};

const getReservationDataFromLocalStorage = () => {
    const storedReservationData = localStorage.getItem('reservationData');
    reservationData.value = storedReservationData ? JSON.parse(storedReservationData) : null;
};

onMounted(() => {
    getReservationDataFromLocalStorage();
});

const showNotificationPending = () => {
    document.getElementById('pap1').style.display = 'block';
    setTimeout(() => {
        document.getElementById('pap1').style.display = 'none';
        router.push("/reservation")
    }, 50000);
};

const route = useRoute();
const { query } = route;
const { status, close } = query;

function traiterPaiement(status, close) {
  if (status === 'pending' && close === 'true') {
    
    showNotificationPending()
    
  } else if (status === 'approved') {

    sendMessage()

  } else {
    console.log('')
  }
}
onMounted(() => {
    const route = useRoute();
    const isCommandeRoute = route.path === '/reservation/callback';
    if (isCommandeRoute) {
        traiterPaiement(status, close);
    }
});
</script>
<style scoped>
.loader {
 position: relative;
 width: 2.5em;
 height: 2.5em;
 transform: rotate(165deg);
}

.loader:before, .loader:after {
 content: "";
 position: absolute;
 top: 50%;
 left: 50%;
 display: block;
 width: 0.5em;
 height: 0.5em;
 border-radius: 0.25em;
 transform: translate(-50%, -50%);
}

.loader:before {
 animation: before8 2s infinite;
}

.loader:after {
 animation: after6 2s infinite;
}

@keyframes before8 {
 0% {
  width: 0.5em;
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }

 35% {
  width: 2.5em;
  box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
 }

 70% {
  width: 0.5em;
  box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
 }

 100% {
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }
}

@keyframes after6 {
 0% {
  height: 0.5em;
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }

 35% {
  height: 2.5em;
  box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
 }

 70% {
  height: 0.5em;
  box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
 }

 100% {
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }
}

.loader {
 position: absolute;
 top: calc(50% - 1.25em);
 left: calc(50% - 1.25em);
}
</style>