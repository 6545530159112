<template>
    <HeaderComponent/>
    <section id="location" class="w-screen h-screen bg-gray-700 ">
        <div class="w-full h-full object-cover">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.0360905299754!2d2.3933433735032197!3d6.3893446247108665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x102355b628d125a5%3A0xb4df44b0389509a7!2zQ2FpbGxlIETigJlvcg!5e0!3m2!1sfr!2sbj!4v1712205757049!5m2!1sfr!2sbj" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="absolute md:w-[250px] w-[220px] h-auto py-[20px] rounded-[5px] shadow-6xl px-[25px] bg-gray-800 md:left-[20%] left-[15px] top-[50%] translate-y-[-50%]">
            <div>
                <h1 class="text-sm text-gray-400 font-bold ">Téléphone</h1>
                <div class="my-[10px] text-white">
                    +229 95461408
                </div>
            </div>
            <div>
                <h1 class="text-sm text-gray-400 font-bold ">Horaire d'ouverture</h1>
                <div class="my-[10px] text-white">
                    <p class="w-full flex my-[5px] justify-between items-center text-sm">
                        <span>Lun-Dim</span>
                        <span>12h30-23h</span>
                    </p>
                </div>
            </div>
            <div>
                <h1 class="text-sm text-gray-400 font-bold ">Localisation</h1>
                <div class="my-[10px] text-white text-sm">
                    Von pavée opposée au CEG zogbo, Cotonou, Bénin
                </div>
            </div>
            <div>
                <button class="w-full p-[10px] rounded-[5px] my-[5px] bg-orange-500 text-center text-white font-bold text-sm">
                    <a href="https://wa.me/+22995461408">Nous contacter</a>
                </button>
                <button @click="toggleMessage()" class="w-full p-[10px] rounded-[5px] border-2 my-[5px] border-orange-500 text-center text-white font-bold text-sm">Nous laisser un message</button>
            </div>
        </div>
        <div v-if="openMessage" class="absolute top-[55%] translate-y-[-50%] left-[50%] md:p-[50px] p-[20px] translate-x-[-50%] w-full h-full md:w-[85%] md:max-w-[800px] md:h-[80%] md:max-h-[450px] bg-gray-300 shadow-8xl flex justify-center items-center flex-col"> 
            <h1 class="font-bold text-center my-[10px]">Vous êtes intéressé ? Écrivez nous ! Laissez nous un message !!</h1>
            <form @submit.prevent="sendMessage" class="w-full relative max-w-[500px]">
                <div class="my-[10px] w-full">
                    <input type="text" required v-model="nomPrenom" placeholder="Nom et Prénoms" class="w-full p-[10px] rounded-[5px] border text-sm font-semibold outline-none bg-gray-200 ">
                </div>
                <div class="my-[10px] w-full">
                    <input type="text" required v-model="emailNum" placeholder="Numéro ou Mail" class="w-full p-[10px] rounded-[5px] border text-sm font-semibold outline-none bg-gray-200 ">
                </div>
                <div class="my-[10px] w-full">
                    <textarea name="" required v-model="message" placeholder="Message" id="" class="w-full p-[10px] h-[150px] resize-none rounded-[5px] border text-sm font-semibold outline-none bg-gray-200 "></textarea>
                </div>
                <div class="my-[10px] w-full flex justify-center items-center">
                    <button type="submit" :disabled="loading" name="" value="Réserver" class="p-[10px] shadow-4xl cursor-pointer px-[20px] rounded-[5px] text-sm font-bold text-gray-300 bg-gray-800 hover:text-white duration-300">
                        <div v-if="loading" >
                            <div class="newtons-cradle">
                                <div class="newtons-cradle__dot"></div>
                                <div class="newtons-cradle__dot"></div>
                                <div class="newtons-cradle__dot"></div>
                                <div class="newtons-cradle__dot"></div>
                            </div>
                        </div>
                        <span v-else>Envoyer</span>
                    </button>
                </div>
            </form>
            <div @click="toggleMessage()" class="w-[25px] h-[25px] rounded-[50%] absolute top-[60px] cursor-pointer md:top-[20px] right-[20px] font-bold bg-black text-white flex justify-center items-center">
                <span class="mb-[5px]">x</span>
            </div>

            <div id="pap" class="absolute hidden top-0 left-0 bottom-0 right-0 w-full h-full bg-white justify-center items-center flex-col">
                <div class="w-[100px] h-[100px] bg-green-600 flex justify-center items-center rounded-[50%] text-3xl text-white">
                    <i class="fa-solid fa-check"></i>
                </div>
                <h1 class="text-5xl text-center my-[5px] font-bold text-gray-700">
                    Thank you !
                </h1>
                <p class="text-center">
                    Votre message a été envoyé avec succès 😊
                </p>
            </div>
        </div>
    </section>
</template>

<script setup>
import HeaderComponent from "@/components/HeaderComponent.vue"
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import axios from 'axios'
const loading = ref(false);

const pageTitle = ref("Contactez-nous - Caille d'Or")
onMounted(() => {
  document.title = pageTitle.value;
});

const router = useRouter()
const openMessage = ref(false)

const toggleMessage = () => {
    openMessage.value = !openMessage.value
}

const nomPrenom = ref('');
const emailNum = ref('');
const message = ref('');

const sendMessage = async () => {
  try {
    loading.value = true;
    const response = await axios.post('/messages', {
      nomPrenom: nomPrenom.value,
      emailNum: emailNum.value,
      message: message.value,
    });

    showNotification();

  } catch (error) {
    console.error('Erreur lors de l\'ajout de la réservation:', error);
  } finally {
        loading.value = false; 
    }
};

const showNotification = () => {
  document.getElementById('pap').style.display = 'flex';
    setTimeout(() => {
        router.push("/")
    }, 2500);
};
</script>

<style scoped>
.newtons-cradle {
 --uib-size: 20px;
 --uib-speed: 1.2s;
 --uib-color: #FFF;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 70px;
 height: var(--uib-size);
}
</style>