<template>
    <div v-if="infoMessage" class="absolute top-[15px] z-50 left-[50%] translate-x-[-50%] w-[95%] rounded-[10px] shadow-4xl flex flex-col justify-center items-center p-[20px] text-gray-700 text-xs font-bold bg-blue-200 max-w-[350px] text-center">
        <i class="fa-solid fa-circle-info mb-[10px] text-blue-500 text-xl"></i>
        <p>
            Veuillez éteindre votre VPN avant l'utilisation de ce système !! L'utilisation éventuelle d'un VPN pourrait entrainer un biais des informations consernant votre commande, ce qui entrainerait des désaggrément pour les deux parties concernés !🫡
        </p>
    </div>
    <div class="w-[90%] absolute top-[30px] max-w-[200px] md:max-w-[400px] flex justify-between items-center">
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-white bg-orange-500 font-bold justify-center"> 1 </div>
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-gray-700 bg-gray-100 font-bold justify-center"> 2 </div>
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-gray-700 bg-gray-100 font-bold justify-center"> 3 </div>
        <div class="w-[90px] md:w-[190px] z-[-1] h-[4px] bg-gray-200 absolute left-0 top-[50%] translate-y-[-50%]"></div>
        <div class="w-[90px] md:w-[190px] z-[-1] h-[4px] bg-gray-200 absolute right-0 top-[50%] translate-y-[-50%]"></div>
    </div>
    <form @submit.prevent="saveFormData" class="overflow-y-auto">
        <div class="w-full relative max-w-[500px]">
            <h1 class="mb-[30px] text-center font-bold text-xl text-gray-500"> Veuillez inscrire vos informations personnelles !</h1>
            <div class="my-[15px] w-full">
                <input type="text" v-model="formData.nomPrenom" required placeholder="Nom et Prénoms" class="w-full p-[10px] rounded-[5px] text-sm font-semibold outline-none bg-gray-100 ">
            </div>
            <div class="my-[15px] w-full">
                <input type="text" v-model="formData.email" placeholder="Email" class="w-full p-[10px] rounded-[5px] text-sm font-semibold outline-none bg-gray-100 ">
            </div>
            <div class="my-[15px] w-full">
                <input type="text" v-model="formData.numero" required placeholder="Numéro" class="w-full p-[10px] rounded-[5px] text-sm font-semibold outline-none bg-gray-100 ">
            </div>
            <div class="my-[15px] w-full">
                <input type="text" v-model="formData.adresse" required placeholder="Adresse" class="w-full p-[10px] rounded-[5px] text-sm font-semibold outline-none bg-gray-100 " :readonly="utiliserLocalisation">
            </div>
            <div class="my-[15px] w-full flex items-center">
                <div class="flex items-center">
                    <input type="radio" name="adresse" checked value="perso" id="adressePerso">
                    <label for="adressePerso" class="mx-[5px] text-sm font-semibold text-gray-700">Choisir mon adresse</label>
                </div>
                <div class="flex items-center mx-[15px]">
                    <input type="radio" name="adresse" value="auto" id="adresseAuto">
                    <label for="adresseAuto" class="mx-[5px] text-sm font-semibold text-gray-700">Utiliser ma localisation</label>
                </div>
            </div>
        </div>
        <div class="absolute bottom-0 w-full p-[25px] left-0 right-0 flex justify-between">
            <button v-if="currentStep !== 1" type="button" class="py-2 px-4 bg-gray-200 hover:bg-gray-300 rounded-md">
                Précédent
            </button>
            <button v-if="currentStep !== totalSteps" type="submit" class="py-2 px-4 absolute right-[25px] bottom-[25px] bg-orange-500 text-white rounded-md">
                Suivant
            </button>
            <button v-else type="button" class="py-2 px-4 absolute right-[25px] bottom-[25px] bg-green-800 text-white rounded-md">
                Soumettre
            </button>
        </div>
    </form>

</template>

<script setup>
import { ref, onMounted, computed, defineProps } from 'vue';

const formData = ref({
    nomPrenom: '',
    email: '',
    numero: '',
    adresse: '',
});

const utiliserLocalisation = ref(false);
const infoMessage = ref(false)


const saveFormData = () => {
    const { nomPrenom, email, numero, adresse } = formData.value;

    const existingData = JSON.parse(localStorage.getItem('personData')) || [];

    const newData = { nomPrenom, email, numero, adresse };

    existingData.push(newData);

    localStorage.setItem('personData', JSON.stringify(existingData));

    formData.value.nomPrenom = '';
    formData.value.email = '';
    formData.value.numero = '';
    formData.value.adresse = '';

    nextStep()
};

const { currentStep, totalSteps, nextStep, prevStep } = defineProps(['currentStep', 'totalSteps', 'nextStep', 'prevStep1', 'prevStep2'])

const handleAdresseAutoChange = (event) => {
    if (event.target.checked) {
        utiliserLocalisation.value = true;
        getLocation();
        infoMessage.value = true;
        setTimeout(() => {
            infoMessage.value = false;
        }, 10000);
    } else {
        utiliserLocalisation.value = false;
    }
};

const getLocation = () => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
    } else {
        console.log("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }
};

const showPosition = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    formData.value.adresse = `Latitude: ${latitude}, Longitude: ${longitude}`;
};

onMounted(() => {
    const adresseAuto = document.getElementById('adresseAuto');
    adresseAuto.addEventListener('change', handleAdresseAutoChange);
});
</script>

<style>

</style>
