<template>
    <footer :class="{'bg-gray-200': $route.path === '/'}" class="relative bottom-0 md:flex h-auto w-screen justify-center items-center p-[25px]">
        <div class="w-full bg-gray-800 rounded-[10px] md:mr-[10px] p-[10px] flex flex-col md:flex-row mb-[15px] md:mb-0 justify-center md:justify-start items-center shadow-4xl">
            <div class="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded-[50%] mb-[10px] md:mb-0 bg-gray-200 mx-[5px] flex justify-center items-center">
                <img src="../assets/images/Logo.jpg" alt="" class="w-full h-full rounded-[50%]">
            </div>
            <!--
            <p class="text-xs text-gray-50 md:ml-[10px] text-center md:text-left">
                 &copy; 2024 Caille d'Or - Tous droits réservés. Découvrez une expérience culinaire unique où la passion rencontre l'innovation. Rejoignez nous pour savourer chaque instant, une bouchée à la fois.
            </p>
            -->
            <p class="text-xs text-gray-50 md:ml-[10px] text-center">
                &copy; 2024 Restaurant Caille d'Or - Tous droits réservés | Développé par <a href="https://vaybe.tech" target="_blank" class="text-bold text-sm text-white"> Vaybe </a>
            </p>
        </div>
        <div class="md:w-[200px] w-full bg-gray-800 rounded-[10px] md:ml-[10px] p-[10px] flex justify-center items-center shadow-4xl">
            <a target="_blank" href="mailto:contact@caille-dor.com" class="w-[35px] h-[35px] rounded-[50%] bg-gray-200 mx-[5px] flex text-orange-500 justify-center items-center"> <i class="fa-solid fa-envelope"></i> </a>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=100075662212464" class="w-[35px] h-[35px] rounded-[50%] bg-gray-200 mx-[5px] flex text-orange-500 justify-center items-center"> <i class="fa-brands fa-facebook"></i> </a>
            <a target="_blank" href="https://www.tiktok.com/@cailledor?_t=8lRHheONgcX&_r=1" class="w-[35px] h-[35px] rounded-[50%] bg-gray-200 mx-[5px] flex text-orange-500 justify-center items-center"> <i class="fa-brands fa-tiktok"></i> </a>
            <a target="_blank" href="https://www.instagram.com/cailledor_officiel?igsh=d3diNG8wZXJ6Z254" class="w-[35px] h-[35px] rounded-[50%] bg-gray-200 mx-[5px] flex text-orange-500 justify-center items-center"> <i class="fa-brands fa-instagram"></i> </a>
        </div>
    </footer>
</template>

<script setup lang="ts">
</script>

<style>

</style>