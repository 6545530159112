<template>
    <HeaderComponent/>
    <section class="h-[400px] w-screen relative flex flex-col justify-center items-center">
        <div class="h-full w-full relative">
            <div class="w-full h-full object-cover">
                <img src="../assets/images/Caille/6714_rs_02_p_2048x1536.jpg" alt="" class="absolute w-full h-full object-cover bg-center">
            </div>
            <div class="absolute flex justify-center items-center flex-col text-white left-0 top-0 right-0 w-full h-full bg-black/50">
                <h1 class="text-4xl font-bold text-center">Menu</h1>
                <div class="relative max-w-[600px] w-[90%] flex flex-col my-[10px]">
                    <div class="my-[10px] w-full">
                        <label for="" class="absolute right-[10px] top-[10px]">
                            <i class="fas fa-search text-xs text-gray-700 font-bold"></i>
                        </label>
                        <input type="text" id="search" v-model="searchQuery" @input="searchMenus" placeholder="Rechercher un plat, une boisson ou autre ..." class="w-full relative text-sm font-bold border pr-[25px] outline-none focus:shadow-5xl p-[10px] text-black rounded-[10px]">
                    </div>
                    <div class="flex items-center ml-[-10px] flex-wrap">
                        <button @click="filterByCategory('Viandes')" :class="{ 'bg-orange-500 text-white': selectedCategory === 'Viandes', 'bg-white': selectedCategory !== 'Viandes' }" class="m-[10px] p-[8px] rounded-[5px] text-xs text-orange-500 shadow-7xl font-bold">Viandes</button>
                        <button @click="filterByCategory('Boissons')" :class="{ 'bg-orange-500 text-white': selectedCategory === 'Boissons', 'bg-white': selectedCategory !== 'Boissons' }" class="m-[10px] p-[8px] rounded-[5px] text-xs text-orange-500 shadow-7xl font-bold">Boissons</button>
                        <button @click="filterByCategory('Accompagnements')" :class="{ 'bg-orange-500 text-white': selectedCategory === 'Accompagnements', 'bg-white': selectedCategory !== 'Accompagnements' }" class="m-[10px] p-[8px] flex rounded-[5px] text-xs text-orange-500 shadow-7xl font-bold">Accompagnements</button>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="relative min-h-screen w-screen py-[20px] flex justify-center items-start">
        <div class="container max-w-[1024px] relative px-[10px] flex justify-center items-center flex-col">
            <div v-show="searchQuery.length === 0 || selectedCategory.value !== ''" ref="menu" class="flex w-full relative pt-[30px] flex-col">
                <h1 class="md:text-2xl text-xl font-bold text-center">
                    Vous avez la posibillité de composer votre plat selon vos goût !! 
                </h1>
                <span class="text-center text-sm md:text-lg font-bold text-gray-700 mb-[50px]">Soyez créatif et surtout faites vous plaisir </span>
            </div>
            <div class="flex w-full relative justify-center items-center flex-wrap">
                <div v-for="(menu, index) in paginatedMenus" :key="index" class="w-[150px] h-[200px] shadow-6xl flex justify-center rounded-[20px] m-[10px] relative overflow-hidden object-cover">
                    <img :src="menu.image" alt="" class="w-full h-full absolute object-cover top-[-30px] object-top">
                    <div class="w-[150px] h-[80px] bg-white absolute bottom-0 rounded-[20px] p-[10px]">
                        <h1 class="text-gray-700 font-bold text-sm leading-3">{{ menu.name }}</h1>
                        <button @click="addToLocalStorage(menu)" class="text-white bg-orange-500 w-[20px] h-[20px] absolute bottom-[10px] right-[10px] rounded-[50%] flex justify-center items-center font-bold pb-[5px]">+</button>
                        <button @click="commander(menu)" class="absolute bottom-[10px] left-[10px] text-xs font-bold text-orange-500">Commander</button>
                    </div>
                    <div class="p-[10px] py-[5px] rounded-[20px] text-xs text-orange-500 font-bold bg-white absolute top-[10px] right-[10px]">{{ menu.price }}F</div>
                </div>
            </div>
            <div class="relative my-[20px] w-full flex justify-center items-center">
                <div class="flex items-center justify-center w-full ">
                    <button v-for="page in generatePageNumbers()" :key="page" @click="changePage(page)" 
                            class="m-[5px] w-[25px] h-[25px] flex justify-center items-center rounded-[50%] text-sm text-orange-500 shadow-7xl font-bold"
                            :class="{ 'bg-orange-500 text-white': page === currentPage }">{{ page }}
                    </button>
                </div>
            </div>
        </div>
    </section>
    <section class="w-screen hidden h-screen z-[100] bg-gray-700 fixed top-0 left-0 right-0 bottom-0">
      <div id="appa" class="w-[90%] h-[380px] fixed max-w-[300px] rounded-[10px] p-[20px] bg-white bottom-[100px] left-[50%] translate-x-[-50%] z-30 shadow-4xl">
        <div class="absolute top-[-30px] left-[50%] translate-x-[-50%] w-[60px] h-[60px] rounded-[50%] bg-white p-[5px]">
          <div class="w-full h-full relative rounded-[50%] bg-green-600 flex justify-center text-2xl text-white items-center"><i class="fa-solid fa-check"></i></div>
        </div>
        <div id="creu" class="py-[20px] relative border-b border-dashed border-gray-500 flex flex-col justify-center items-center">
          <h1 class="text-xl font-bold text-black">Thank you !</h1>
          <p class="text-xs text-gray-500">Votre commande a été lancé avec succès</p>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-between items-center text-xs uppercase text-gray-500 ">
            <span>DATE</span>
            <span>Time</span>
          </div>
          <div class="w-full flex justify-between items-center text-sm font-bold text-black ">
            <span>12 Avril, 2024</span>
            <span>15:28</span>
          </div>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-center items-start flex-col">
            <span class="text-xs uppercase text-gray-500 ">From</span>
            <span class="text-sm font-bold text-black">Prénom Nom</span>
            <span class="text-xs text-gray-700">adresse@mail.com</span>
          </div>
        </div>
        <div class="flex w-full justify-between items-center py-[10px]">
          <div class="w-full flex justify-center flex-col items-start text-xs uppercase text-gray-500 ">
            <span class="text-xs uppercase text-gray-500 ">Montant</span>
            <span class="text-sm font-bold text-black">FCFA 15000.00</span>
          </div>
          <div class="w-full flex justify-center flex-col items-end text-xs uppercase text-gray-500 ">
            <div class="text-xs uppercase text-gray-400 border-gray-400 border rounded-[5px] p-[5px]">
              Completé
            </div>
          </div>
        </div>
        <div class="mt-[15px] p-[10px] w-full rounded-[10px] bg-gray-100 flex justify-start items-center">
          <div class="w-[35px] h-[35px] rounded-[50%] flex justify-center items-center relative overflow-hidden">
            <img src="../assets/images/Caille/MTN Momo.png" alt="" class="w-full h-full last:">
          </div>
          <div class="mx-[10px] flex flex-col items-start justify-center">
            <span class="text-sm font-bold text-black">MTN Momo</span>
            <span class="text-xs text-gray-500 ">Envoyer par le numero **60</span>
          </div>
        </div>
        <div class="absolute bottom-[-70px] left-[50%] translate-x-[-50%] w-[35px] h-[35px] rounded-[50%] bg-gray-600 p-[5px]">
          <div class="w-full h-full relative rounded-[50%] flex justify-center text-2xl text-white items-center"><i class="fa-solid fa-xmark"></i></div>
        </div>
      </div>
    </section>
    <FooterComponent/>
</template>

<script setup>
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import { ref, onMounted, computed } from 'vue';
import { useRouter } from "vue-router";

const router = useRouter()

const pageTitle = ref("Menu - Caille d'Or")
onMounted(() => {
  document.title = pageTitle.value;
});


const menu = ref(null);
const itemsPerPage = 10;
const currentPage = ref(1);
const totalPages = computed(() => Math.ceil(filteredMenus.value.length / itemsPerPage)); // Calcul du nombre total de pages

const generatePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages.value; i++) {
        pages.push(i);
    }
    return pages;
};

const changePage = (page) => {
    currentPage.value = page;
    menu.value.scrollIntoView({ behavior: 'smooth' });
};

const paginatedMenus = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage;
    const endIndex = currentPage.value * itemsPerPage;
    return filteredMenus.value.slice(startIndex, endIndex);
});


const selectedMenus = ref([]);

const addToLocalStorage = (menu) => {
    let storedMenus = JSON.parse(localStorage.getItem('selectedMenus')) || [];

    const existingMenuIndex = storedMenus.findIndex(item => item.name === menu.name);

    if (existingMenuIndex !== -1) {
        storedMenus[existingMenuIndex].units++;
        storedMenus[existingMenuIndex].price = menu.price * storedMenus[existingMenuIndex].units;
    } else {
        storedMenus.push({ ...menu, units: 1 });
    }

    localStorage.setItem('selectedMenus', JSON.stringify(storedMenus));
    selectedMenus.value = [...storedMenus];

    const event = new Event('selectedMenusC');
    window.dispatchEvent(event);
};

const commander = (menu) => {
    let storedMenus = JSON.parse(localStorage.getItem('selectedMenus')) || [];

    const existingMenuIndex = storedMenus.findIndex(item => item.name === menu.name);

    if (existingMenuIndex !== -1) {
        storedMenus[existingMenuIndex].units++;
        storedMenus[existingMenuIndex].price = menu.price * storedMenus[existingMenuIndex].units;
    } else {
        storedMenus.push({ ...menu, units: 1 });
    }

    localStorage.setItem('selectedMenus', JSON.stringify(storedMenus));
    selectedMenus.value = [...storedMenus];

    const event = new Event('selectedMenusC');
    window.dispatchEvent(event);
    router.push('/commande')
};

const resetSelectedMenus = () => {
    localStorage.removeItem('selectedMenus');
    selectedMenus.value = [];
};

const menus = ref([
  { 
    name: "Poulet braisé Entier",
    image: "../img/Caille/Poulet-braisé-Entier.jpeg",
    price: 4000,
    unitPrice: 4000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Poulet braisé Demi",
    image: "../img/Caille/Poulet-braisé-Entier.jpeg",
    price: 2000,
    unitPrice: 2000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Poulet frit Entier",
    image: "../img/Caille/Poulet_frit.jpg",
    price: 5000,
    unitPrice: 5000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Poulet frit Demi",
    image: "../img/Caille/Poulet_frit_demi.jpeg",
    price: 2500,
    unitPrice: 2500,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pils",
    image: "../img/Caille/Pils.jpg",
    price: 600,
    unitPrice: 600,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Béninoise",
    image: "../img/Caille/Beninoise.jpg",
    price: 400,
    unitPrice: 400,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Beaufort",
    image: "../img/Caille/Beaufort.jpeg",
    price: 500,
    unitPrice: 500,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Racine",
    image: "../img/Caille/Racine.jpg",
    price: 900,
    unitPrice: 900,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Piron",
    image: "../img/Caille/Piron.jpg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Aloco",
    image: "../img/Caille/Aloco.jpeg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Igname frite",
    image: "../img/Caille/Igname-frite.jpg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Akassa",
    image: "../img/Caille/Akassa.jpg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Caille braisée Entière",
    image: "../img/Caille/Caille_braise.png",
    price: 1500,
    unitPrice: 1500,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Caille frite Entière",
    image: "../img/Caille/cailles_frit.jpg",
    price: 2000,
    unitPrice: 2000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Viande de Mouton",
    image: "../img/Caille/viande-de-mouton.jpeg",
    price: 2000,
    unitPrice: 2000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Brochettes de boeuf",
    image: "../img/Caille/Brochettes_de_boeufs.jpg",
    price: 700,
    unitPrice: 700,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Doppel",
    image: "../img/Caille/Doppel.jpg",
    price: 700,
    unitPrice: 700,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Eku",
    image: "../img/Caille/Eku.jpg",
    price: 700,
    unitPrice: 700,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Guiness",
    image: "../img/Caille/Guiness.jpeg",
    price: 800,
    unitPrice: 800,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Panaché",
    image: "../img/Caille/Panache.jpeg",
    price: 500,
    unitPrice: 500,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Bomiwo",
    image: "../img/Caille/Bomiwo.jpg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Amiwo",
    image: "../img/Caille/Amiwo.jpg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Riz blanc",
    image: "../img/Caille/Riz-blanc.jpeg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Spaghetti",
    image: "../img/Caille/Spaghetti.jpg",
    price: 500,
    unitPrice: 500,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Lapin braisé Entier",
    image: "../img/Caille/Lapin-braisé-Entier.jpeg",
    price: 6000,
    unitPrice: 6000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Lapin frit Entier",
    image: "../img/Caille/Lapin_frit_Entier.jpg",
    price: 7000,
    unitPrice: 7000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Poisson braisé",
    image: "../img/Caille/Poisson-braisé.jpeg",
    price: 4000,
    unitPrice: 4000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Poisson frit",
    image: "../img/Caille/Poisson_frit.jpg",
    price: 5000,
    unitPrice: 5000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Cocktail",
    image: "../img/Caille/Cocktail.jpg",
    price: 400,
    unitPrice: 400,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Moka",
    image: "../img/Caille/Moka.jpg",
    price: 400,
    unitPrice: 400,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pamplemousse",
    image: "../img/Caille/Pamplemousse.jpg",
    price: 400,
    unitPrice: 400,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "World cola",
    image: "../img/Caille/World-cola.jpg",
    price: 400,
    unitPrice: 400,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Couscous",
    image: "../img/Caille/Couscous.jpeg",
    price: 1000,
    unitPrice: 1000,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Riz gras",
    image: "../img/Caille/Riz-gras.jpeg",
    price: 1000,
    unitPrice: 1000,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pomme frite surgelée",
    image: "../img/Caille/Pomme-frite.jpg",
    price: 1000,
    unitPrice: 1000,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pomme frite locale",
    image: "../img/Caille/Pomme-frite.jpg",
    price: 1000,
    unitPrice: 1000,
    category: "Accompagnements" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "XXL",
    image: "../img/Caille/XXL.jpg",
    price: 700,
    unitPrice: 700,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Fifa",
    image: "../img/Caille/Fifa.jpg",
    price: 600,
    unitPrice: 600,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Comtesse citron",
    image: "../img/Caille/Comtesse-citron.jpeg",
    price: 600,
    unitPrice: 600,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Kwabo citron",
    image: "../img/Caille/Kuwabo-citron.jpg",
    price: 600,
    unitPrice: 600,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pintade braisée Entière",
    image: "../img/Caille/pintade-farcie.jpg",
    price: 6000,
    unitPrice: 6000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pintade frite Entière",
    image: "../img/Caille/Pintade_frit_Entier.jpg",
    price: 6000,
    unitPrice: 6000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pintade frite moitiée",
    image: "../img/Caille/Pintade_frite_demi.png",
    price: 3000,
    unitPrice: 3000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Pintade braisée moitiée",
    image: "../img/Caille/pintade-braise_Entier.jpg",
    price: 3000,
    unitPrice: 3000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Bissap",
    image: "../img/Caille/Bissap.jpg",
    price: 500,
    unitPrice: 500,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Bissap",
    image: "../img/Caille/Bissap.jpg",
    price: 1500,
    unitPrice: 1500,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Jus d'ananas",
    image: "../img/Caille/Jus_ananas.jpg",
    price: 500,
    unitPrice: 500,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Jus d'ananas",
    image: "../img/Caille/Jus_ananas.jpg",
    price: 1500,
    unitPrice: 1500,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Tilapia braisée",
    image: "../img/Caille/tilapia-braise.jpg",
    price: 3000,
    unitPrice: 3000,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Tilapia frit",
    image: "../img/Caille/Tilapia-frit.jpg",
    price: 3500,
    unitPrice: 3500,
    category: "Viandes" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Jus de gingembre",
    image: "../img/Caille/Jus_de_gimgembre.jpg",
    price: 500,
    unitPrice: 500,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Vin Rouge",
    image: "../img/Caille/Vin-rouge.jpg",
    price: 5000,
    unitPrice: 5000,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  { 
    name: "Vin Blanc",
    image: "../img/Caille/Vin-blanc.jpg",
    price: 4000,
    unitPrice: 4000,
    category: "Boissons" // Ajoutez une catégorie pour chaque élément
  },
  // Ajoutez d'autres éléments de menu avec leurs catégories ici
]);

const selectedCategory = ref('');

const filterByCategory = (category) => {
    selectedCategory.value = category;
    searchMenus();
}
const searchQuery = ref('');
const filteredMenus = ref([]);

const searchMenus = () => {
    filteredMenus.value = menus.value.filter(menu =>
        (menu.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        menu.category.toLowerCase().includes(searchQuery.value.toLowerCase())) &&
        (selectedCategory.value === '' || menu.category === selectedCategory.value)
    );
}

onMounted(() => {
    filteredMenus.value = menus.value; 
});
</script>

<style scoped>
#appa {
    animation: slide-out 2s forwards;
}
  
@keyframes slide-out {
    0% {
      transform: translateY(100%) translateX(-50%);
      left: 50%;
    }
    100% {
      transform: translateY(0) translateX(-50%);
      left: 50%;;
    }
}
</style>