<template>
    <div class="w-[90%] absolute top-[30px] max-w-[200px] md:max-w-[400px] flex justify-between items-center">
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-white bg-orange-500 font-bold justify-center"> 1 </div>
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-white bg-orange-500 font-bold justify-center"> 2 </div>
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-gray-700 bg-gray-100 font-bold justify-center"> 3 </div>
        <div class="w-[90px] md:w-[190px] z-[-1] h-[4px] bg-orange-500 absolute left-0 top-[50%] translate-y-[-50%]"></div>
        <div class="w-[90px] md:w-[190px] z-[-1] h-[4px] bg-gray-200 absolute right-0 top-[50%] translate-y-[-50%]"></div>
    </div>
    <form @submit.prevent="savePaiementData">
        <div class="w-full relative max-w-[500px]">
            <h1 class="mb-[30px] text-center font-bold text-xl text-gray-500"> Choisissez votre moyen de paiement !</h1>
            <div class="my-[15px] w-full flex justify-center items-center">
                <div class="flex justify-center items-center">
                    <input type="radio" v-model="formDatas.paiement" required name="paiement" value="MTN Momo" id="mtn">
                    <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center mr-[10px] ml-[5px] shadow-9xl items-center relative overflow-hidden">
                      <img src="../assets/images/Caille/MTN Momo.png" alt="" class="w-full h-full last:">
                    </div>
                    <label for="mtn" class="mx-[5px] text-sm font-bold text-gray-700">MTN Momo</label>
                </div>
                <div class="flex justify-center items-center mx-[15px]">
                    <input type="radio" v-model="formDatas.paiement" required name="paiement" value="Moov Money" id="moov">
                    <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center mr-[10px] ml-[5px] shadow-9xl items-center relative overflow-hidden">
                      <img src="../assets/images/Caille/Moov Money.png" alt="" class="w-full h-full last:">
                    </div>
                    <label for="moov" class="mx-[5px] text-sm font-bold text-gray-700">Moov Money</label>
                </div>
            </div>
            <div class="my-[15px] w-full relative">
                <input type="text" required v-model="formDatas.numero" placeholder="Numéro momo" class="w-full p-[10px] pl-[50px] rounded-[5px] text-sm font-semibold outline-none bg-gray-100 ">
                <label for="" class="absolute top-[10px] left-[10px] text-sm font-bold text-gray-800 ">+229</label>
            </div>

        </div>
        <div class="absolute bottom-0 w-full p-[25px] left-0 right-0 flex justify-between">
            <button v-if="currentStep !== 1" @click="prevStep1" type="button" class="py-2 px-4 bg-gray-200 hover:bg-gray-300 rounded-md">
                Précédent
            </button>
            <button v-if="currentStep !== totalSteps" type="submit" class="py-2 px-4 absolute right-[25px] bottom-[25px] bg-orange-500 text-white rounded-md">
                Suivant
            </button>
            <button v-else type="button" class="py-2 px-4 absolute right-[25px] bottom-[25px] bg-green-800 text-white rounded-md">
                Soumettre
            </button>
        </div>
    </form>
</template>

<script setup>
import { ref, defineProps } from 'vue';

const formDatas = ref({
    paiement: '',
    numero: '',
});

// Fonction pour sauvegarder les informations saisies dans le local storage
const savePaiementData = () => {
    // Récupérer les données actuelles du stockage local
    const { paiement, numero } = formDatas.value;

    const existingData = JSON.parse(localStorage.getItem('paiementData')) || [];

    const newData = { paiement, numero };

    existingData.push(newData);

    localStorage.setItem('paiementData', JSON.stringify(existingData));

    formDatas.value.paiement = '';
    formDatas.value.numero = '';

    nextStep()
};

const { currentStep, totalSteps, nextStep, prevStep } = defineProps(['currentStep', 'totalSteps', 'nextStep', 'prevStep1', 'prevStep2'])
</script>

<style>

</style>