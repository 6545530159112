<template>
    <div class="w-[90%] absolute top-[30px] max-w-[200px] md:max-w-[400px] flex justify-between items-center">
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-white bg-orange-500 font-bold justify-center"> 1 </div>
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-white bg-orange-500 font-bold justify-center"> 2 </div>
        <div class="w-[30px] h-[30px] rounded-[50%] flex items-center text-white bg-orange-500 font-bold justify-center"> 3 </div>
        <div class="w-[90px] md:w-[190px] z-[-1] h-[4px] bg-orange-500 absolute left-0 top-[50%] translate-y-[-50%]"></div>
        <div class="w-[90px] md:w-[190px] z-[-1] h-[4px] bg-orange-500 absolute right-0 top-[50%] translate-y-[-50%]"></div>
    </div>
    <div class="w-full relative max-w-[500px]">
        <h1 class="my-[10px] text-center font-bold text-xl text-gray-500"> Confirmation des informations </h1>
        <h1 class="mb-[30px] text-xs text-gray-700 text-center font-semibold">Appuyez sur le bouton soumettre si vous confirmez les informations ci dessous </h1>
        <div class="my-[15px] w-full flex justify-between items-center">
            <div class="flex text-sm font-bold text-gray-600">
                Nom et prénoms :
            </div>
            <div class="flex mx-[15px] text-sm text-gray-700 text-right">
                {{ personData[0].nomPrenom }}
            </div>
        </div>
        <div class="my-[15px] w-full flex justify-between items-center">
            <div class="flex text-sm font-bold text-gray-600">
                Email :
            </div>
            <div class="flex mx-[15px] text-sm text-gray-700 text-right">
                <span v-if="personData[0].email !== ''">{{ personData[0].email }}</span>
                <span v-else>...</span>
            </div>
        </div>
        <div class="my-[15px] w-full flex justify-between items-center">
            <div class="flex text-sm font-bold text-gray-600">
                Numéro :
            </div>
            <div class="flex mx-[15px] text-sm text-gray-700 text-right">
                {{ personData[0].numero }}
            </div>
        </div>
        <div class="my-[15px] w-full flex justify-between items-center">
            <div class="flex text-sm font-bold text-gray-600">
                Adresse :
            </div>
            <div class="flex mx-[15px] text-sm text-gray-700 text-right">
                {{ personData[0].adresse }}
            </div>
        </div>
        <div class="my-[15px] w-full flex justify-between items-center">
            <div class="flex text-sm font-bold text-gray-600">
                Moyen de paiement :
            </div>
            <div class="flex mx-[15px] text-sm text-gray-700 text-right">
                {{ paiementData[0].paiement }}
            </div>
        </div>
        <div class="my-[15px] w-full flex justify-between items-center">
            <div class="flex text-sm font-bold text-gray-600">
                Numéro du paiement :
            </div>
            <div class="flex mx-[15px] text-sm text-gray-700 text-right">
                {{ paiementData[0].numero }}
            </div>
        </div>
        <div class="my-[15px] w-full flex justify-between items-center">
            <div class="flex text-sm font-bold text-gray-600">
                Montant à payer :
            </div>
            <div class="flex mx-[15px] text-sm text-blue-950 font-bold text-right">
                {{ total }} FCFA
            </div>
        </div>
    </div>
    <div class="absolute bottom-0 w-full p-[25px] left-0 right-0 flex justify-between">
        <button v-if="currentStep !== 1" @click="prevStep2" type="button" class="py-2 px-4 bg-gray-200 hover:bg-gray-300 rounded-md">
            Précédent
        </button>
        <button v-if="currentStep !== totalSteps" type="button" @click="nextStep" class="py-2 px-4 absolute right-[25px] bottom-[25px] bg-orange-500 text-white rounded-md">
            Suivant
        </button>
        <button v-else type="button" :disabled="loading" @click="creerCommande()" class="py-2 px-4 absolute right-[25px] bottom-[25px] hover:bg-green-800 bg-green-700 font-bold text-sm text-white rounded-md">
            <div v-if="loading" >
                <div class="newtons-cradle">
                    <div class="newtons-cradle__dot"></div>
                    <div class="newtons-cradle__dot"></div>
                    <div class="newtons-cradle__dot"></div>
                    <div class="newtons-cradle__dot"></div>
                </div>
            </div>
            <span v-else>Soumettre</span>
        </button>
    </div>
    <section id="pap" class="w-screen hidden h-screen z-[100] bg-gray-700 fixed top-0 left-0 right-0 bottom-0">
      <div id="appa" class="w-[90%] h-[380px] fixed max-w-[300px] rounded-[10px] p-[20px] bg-white bottom-[100px] left-[50%] translate-x-[-50%] z-30 shadow-4xl">
        <div class="absolute top-[-30px] left-[50%] translate-x-[-50%] w-[60px] h-[60px] rounded-[50%] bg-white p-[5px]">
          <div class="w-full h-full relative rounded-[50%] bg-green-600 flex justify-center text-2xl text-white items-center"><i class="fa-solid fa-check"></i></div>
        </div>
        <div id="creu" class="py-[20px] relative border-b border-dashed border-gray-500 flex flex-col justify-center items-center">
          <h1 class="text-xl font-bold text-black">Thank you !</h1>
          <p class="text-xs text-gray-500">Votre commande a été éffectué avec succès</p>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-between items-center text-xs uppercase text-gray-500 ">
            <span>DATE</span>
            <span>Time</span>
          </div>
          <div class="w-full flex justify-between items-center text-sm font-bold text-black ">
            <span>{{ currentDate }}</span>
            <span>{{ currentTime }}</span>
          </div>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-center items-start flex-col">
            <span class="text-xs uppercase text-gray-500 ">From</span>
            <span class="text-sm font-bold text-black">{{ personData[0].nomPrenom }}</span>
            <span class="text-xs font-bold text-gray-700">{{ personData[0].email }}</span>
          </div>
        </div>
        <div class="flex w-full justify-between items-center py-[10px]">
          <div class="w-full flex justify-center flex-col items-start text-xs uppercase text-gray-500 ">
            <span class="text-xs uppercase text-gray-500 ">Montant</span>
            <span class="text-sm font-bold text-black">FCFA {{ total }}.00</span>
          </div>
          <div class="w-full flex justify-center flex-col items-end text-xs uppercase text-gray-500 ">
            <div class="text-xs uppercase text-gray-400 border-gray-400 border rounded-[5px] p-[5px]">
              Completé
            </div>
          </div>
        </div>
        <div class="mt-[15px] p-[10px] w-full rounded-[10px] bg-gray-100 flex justify-start items-center">
          <div class="flex justify-center items-center">
            <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center shadow-6xl items-center relative overflow-hidden">
              <img src="../assets/images/Caille/MTN Momo.png" alt="" class="w-full h-full last:">
            </div>
            <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center ml-[5px] shadow-6xl items-center relative overflow-hidden">
              <img src="../assets/images/Caille/Moov Money.png" alt="" class="w-full h-full last:">
            </div>
          </div>
          <div class="mx-[10px] flex flex-col items-start justify-center">
            <span class="text-sm font-bold text-black">Mobile Money</span>
            <span class="text-xs text-gray-500 ">Envoyer par le numero {{ paiementData[0].numero }}</span>
          </div>
        </div>
        <div class="absolute bottom-[-70px] left-[50%] translate-x-[-50%] w-[35px] h-[35px] rounded-[50%] bg-gray-600 p-[5px]">
          <div class="w-full h-full relative rounded-[50%] cursor-pointer flex justify-center text-2xl text-white items-center" @click="afterReservation()"><i class="fa-solid fa-xmark"></i></div>
        </div>
      </div>
    </section>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import axios from 'axios';
import { useRouter } from "vue-router"
const loading = ref(false);

const router = useRouter()


function afterReservation() {
    localStorage.removeItem('selectedMenus');
    localStorage.removeItem('personData');
    localStorage.removeItem('paiementData');
    router.push("/")
}

const creerCommande = async () => {
    try {
        loading.value = true;
        const commandeData = {
            selectedMenus: storedMenus,
            paiementData: paiementData,
            personData: personData
        };

        const response = await axios.post('/paiement', commandeData, {
        headers: {
            'Content-Type': 'application/json'
        }
        });

        console.log('Commande créée avec succès:', response.data);
        window.location.href = response.data.redirectUrl;
    } catch (error) {
        console.error('Erreur lors de la création de la commande:', error);
    } finally {
        loading.value = false; 
      }
};

const showNotification = () => {
    document.getElementById('pap').style.display = 'block';
};

let storedMenus = JSON.parse(localStorage.getItem('selectedMenus')) || [];

const updateLocalStorage = () => {
    localStorage.setItem('selectedMenus', JSON.stringify(storedMenus));
};

const total = ref(0);

const storedCategories = ref([
    { name: 'Viandes', units: 0, total: 0 },
    { name: 'Boissons', units: 0, total: 0 },
    { name: 'Accompagnements', units: 0, total: 0 },
    { name: 'Offres Spéciales', units: 0, total: 0 }
]);

// Fonction pour mettre à jour les catégories et le total
const updateSummary = () => {
    total.value = 0;
    storedMenus.forEach(menu => {
        const category = storedCategories.value.find(cat => cat.name === menu.category);
        if (category) {
            category.units += menu.units;
            category.total += menu.units * menu.unitPrice; // Utilisation du prix unitaire
            total.value += menu.units * menu.unitPrice; // Utilisation du prix unitaire
        }
    });
};

// Appel de la fonction de mise à jour lors de l'initialisation
onMounted(() => {
    updateSummary();
});

const personData = JSON.parse(localStorage.getItem('personData')) || null;
const paiementData = JSON.parse(localStorage.getItem('paiementData')) || null;

const currentDate = ref('');
const currentTime = ref('');

// Fonction pour obtenir la date et l'heure actuelles
const getCurrentDateTime = () => {
    const now = new Date();
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };

    currentDate.value = now.toLocaleDateString('fr-FR', dateOptions);
    currentTime.value = now.toLocaleTimeString('fr-FR', timeOptions);
};

// Appeler la fonction pour obtenir la date et l'heure actuelles lors du montage du composant
onMounted(() => {
    getCurrentDateTime();
});

const { currentStep, totalSteps, nextStep, prevStep } = defineProps(['currentStep', 'totalSteps', 'nextStep', 'prevStep1', 'prevStep2'])

</script>

<style scoped>

.newtons-cradle {
 --uib-size: 20px;
 --uib-speed: 1.2s;
 --uib-color: #FFF;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 70px;
 height: var(--uib-size);
}
</style>