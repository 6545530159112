<template>
    <router-view/>
</template>

<style lang="scss">
#parent::-webkit-scrollbar {
    width: 0%;
}
#afbe::after {
    content: "";
    width: 0;
    height: 0;
    border-width: 20px;
    border-style: solid;
    border-color: transparent rgb(31 41 55) transparent transparent;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    right: -2px;
    z-index: 12;
}

#afbe::before {
    content: "";
    width: 0;
    height: 0;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent transparent rgb(31 41 55);
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: -2px;
    z-index: 12;
}

.elem::before {
    content: "";
    width: 0;
    height: 0;
    border-width: 15px;
    border-style: solid;
    border-color: rgb(243 244 246 ) transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    z-index: 12;
    top: -2px;
}

.sticky {
    background: rgb(31 41 55 );
}

.open .ouvrir {
    display: none;
}

.fermer {
    display: none;
}
.open .fermer {
    display: flex;
}
iframe {
    position: absolute;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

#excep {
    font-family: 'Fantica Demo', sans-serif;
}

#creu::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(55, 65, 81);
    position: absolute;
    bottom: -5px;
    left: -25px;
}
#creu::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(55, 65, 81);
    position: absolute;
    bottom: -5px;
    right: -25px;
}

#appa {
    animation: slide-out 2s forwards;
}
  
@keyframes slide-out {
    0% {
      transform: translateY(100%) translateX(-50%);
      left: 50%;
    }
    100% {
      transform: translateY(0) translateX(-50%);
      left: 50%;;
    }
}

.newtons-cradle {
 --uib-size: 20px;
 --uib-speed: 1.2s;
 --uib-color: #474554;
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 80px;
 height: var(--uib-size);
}

.newtons-cradle__dot {
 position: relative;
 display: flex;
 align-items: center;
 height: 10px;
 width: 10px;
 transform-origin: center top;
}

.newtons-cradle__dot::after {
 content: '';
 display: block;
 width: 10px;
 height: 10px;
 border-radius: 50%;
 background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
 animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
 animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
 0% {
  transform: rotate(0deg);
  animation-timing-function: ease-out;
 }

 25% {
  transform: rotate(70deg);
  animation-timing-function: ease-in;
 }

 50% {
  transform: rotate(0deg);
  animation-timing-function: linear;
 }
}

@keyframes swing2 {
 0% {
  transform: rotate(0deg);
  animation-timing-function: linear;
 }

 50% {
  transform: rotate(0deg);
  animation-timing-function: ease-out;
 }

 75% {
  transform: rotate(-70deg);
  animation-timing-function: ease-in;
 }
}

</style>
