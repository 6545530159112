<template>
    <header ref="myheader"
        :class="{
            'w-screen flex justify-center items-center fixed top-0 z-50 h-[70px] duration-300': true,
            'bg-gray-800': $route.path !== '/' && $route.path !== '/menu' }">
        <div class="container w-full flex justify-between items-center md:relative md:px-[25px] px-[10px]">
            <div class="items-center flex md:contents justify-between relative w-full h-full md:w-auto ">
                <img src="../assets/images/Logo.jpg" alt="" class="w-[50px] h-[50px] rounded-[50%]">
                <div class="flex items-center justify-center md:hidden relative">
                    <button class="mx-[20px] relative"> 
                        <i class="fa-solid fa-cart-shopping text-2xl text-white relative" ref="cartIcon"></i>
                        <!-- Badge de notification -->
                        <div class="absolute top-[-5px] right-[-5px] bg-red-800 w-[15px] h-[15px] rounded-[50%] flex justify-center items-center text-xs font-bold text-gray-100 animate-pulse"
                            v-show="cartItemCount > 0">
                            {{ cartItemCount }}
                        </div>
                    </button>
                    <span class="text-3xl text-white cursor-pointer mx-2 md:hidden flex" @click="toggleMenu()">
                        <div><i class="fa-solid fa-bars ouvrir"></i></div>
                        <div><i class="fa-solid fa-xmark fermer"></i></div>
                    </span>
                </div>
            </div>
            <nav 
            :class="{
              'top-[50px]': isMenuOpen,
              'opacity-100': isMenuOpen,
              'opacity-0': !isMenuOpen,
              'translate-y-[100%] md:translate-y-0': !isMenuOpen
            }"
            class="absolute md:static overflow-y-auto top-[70px] duration-300 ease-in-out md:opacity-100 p-[50px] md:p-0 md:top-0 h-[calc(100vh-70px)] md:h-full bg-black md:bg-transparent w-screen left-0 right-0 md:w-auto"
            >
                <ul class="flex items-center text-white font-semibold justify-center md:flex-row flex-col">
                    <li @click="goToHomePage()"
                        :class="{
                            'md:mx-[15px] my-[15px] md:my-0 w-full text-center md:w-auto cursor-pointer': true,
                            'font-bold text-orange-500': $route.path === '/'}">
                        Accueil
                    </li>
                    <li @click="goToMenuPage()"
                        :class="{
                            'md:mx-[15px] my-[15px] md:my-0 w-full text-center md:w-auto cursor-pointer': true,
                            'font-bold text-orange-500': $route.path === '/menu'}">
                        Menu
                    </li>
                    <li @click="goToReservationPage()"
                        :class="{
                            'md:mx-[15px] my-[15px] md:my-0 w-full text-center md:w-auto cursor-pointer': true,
                            'font-bold text-orange-500': $route.path === '/reservation'}">
                        Réservation
                    </li>
                    <li @click="goToContactPage()"
                        :class="{
                            'md:mx-[15px] my-[15px] md:my-0 w-full text-center md:w-auto cursor-pointer': true,
                            'font-bold text-orange-500': $route.path === '/contact'}">
                        Contact
                    </li>
                    <li @click="goToCommandePage()"
                        :class="{
                            'md:mx-[15px] my-[15px] md:my-0 w-full text-center md:w-auto text-sm text-white p-[10px] cursor-pointer py-[8px] rounded-[5px]': true,
                            'font-bold text-white bg-orange-500': $route.path === '/commande',
                            'border-2 border-white': $route.path !== '/commande'}">
                        Commande
                    </li>
                    <button class="mx-[15px] text-lg text-white font-semibold hidden md:flex">
                        <i class="fas fa-cart-shopping"></i>
                        <div class="absolute top-0 right-0 bg-red-500 w-[15px] h-[15px] rounded-[50%] flex justify-center items-center text-xs font-bold text-white animate-pulse"
                            v-show="cartItemCount > 0">
                            {{ cartItemCount }}
                        </div>
                    </button>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const isMenuOpen = ref(false);
const cartItemCount = ref(0);

const myheader = ref(null);

const router = useRouter();

onMounted(() => {
    window.addEventListener("scroll", handleScroll);
    updateCartItemCount();
});

function toggleMenu() {
    isMenuOpen.value = !isMenuOpen.value;
    myheader.value.classList.add("sticky");
}

function handleScroll() {
    if (myheader.value) {
        if (window.scrollY > 0) {
            myheader.value.classList.add("sticky");
        } else {
            myheader.value.classList.remove("sticky");
        }
    }
}

function goToHomePage() {
    router.push("/");
}
function goToReservationPage() {
    router.push("/reservation");
}
function goToMenuPage() {
    router.push("/menu");
}
function goToCommandePage() {
    router.push("/commande");
}
function goToContactPage() {
    router.push("/contact");
}

function updateCartItemCount() {
    const storedMenus = JSON.parse(localStorage.getItem("selectedMenus")) || [];
    cartItemCount.value = storedMenus.length;
}

window.addEventListener('storage', (event) => {
    if ( event.key === "selectedMenus.units" ) {
        updateCartItemCount();
    }
} )

window.addEventListener('selectedMenusC', updateCartItemCount )

</script>

<style>


</style>