<template>
    <HeaderComponent/>
    <section class="my-[70px] w-screen h-full relative py-[30px] flex flex-col justify-center items-center">
        <div v-if="errorMessage" class="absolute top-[15px] z-50 left-[50%] translate-x-[-50%] w-[95%] rounded-[10px] shadow-4xl flex flex-col justify-center items-center p-[20px] text-gray-700 text-xs font-bold bg-red-100 max-w-[350px] text-center">
            <i class="fa-solid fa-circle-xmark mb-[10px] text-red-500 text-xl"></i>
            <p>
                {{ errorMessage }}
            </p>
        </div>
        <div class="container px-[20px] flex justify-center items-center w-full relative">
            <div class="w-full relative flex md:flex-row flex-col justify-center items-start">
                <div id="recap" class="w-full h-auto md:flex flex-col my-[10px] relative md:mr-[10px]">
                    <div v-for="(menu, index) in storedMenus" :key="index" class="flex items-center bg-gray-200 w-full py-[10px] my-[5px] px-[20px] border-gray-300">
                        <img :src="menu.image" alt="menu.name" class="w-[50px] h-[50px] object-cover mr-[10px]">
                        <div class="flex-grow">
                            <div class="text-gray-700 font-bold">{{ menu.name }}</div>
                            <div class="text-gray-800 text-sm">{{ menu.unitPrice }} FCFA</div>
                        </div>
                        <div class="flex items-center">
                            <button @click="decreaseQuantity(menu)" class="px-2 py-1 text-sm bg-white text-gray-700 rounded-l">-</button>
                            <span class="px-2 py-1 text-sm bg-white text-gray-700">{{ menu.units }}</span>
                            <button @click="increaseQuantity(menu)" class="px-2 py-1 text-sm bg-white text-gray-700 rounded-r">+</button>
                        </div>
                    </div>
                </div>


                <div class="md:w-[350px] h-auto w-full relative flex my-[10px] flex-col md:ml-[10px]">
                    <div class="w-full relative p-[20px] bg-gray-100 ">
                        <h1 class="text-gray-700 text-center font-bold">Résumé d'achat</h1>

                        <!-- Affichage des éléments sélectionnés -->
                        <div v-for="(category, index) in storedCategories" :key="index" class="w-full border-b py-[10px]">
                            <h1 class="text-black font-bold text-left">{{ category.name }} :</h1>
                            <div class="w-full flex justify-between items-center text-sm font-semibold">
                                <span>{{ category.units }} <span v-if="category.units > 1">Unités</span> <span v-else>Unité</span></span>
                                <span>{{ category.total }} FCFA</span>
                            </div>
                        </div>

                        <!-- Affichage du total -->
                        <div class="w-full flex justify-between items-center font-bold text-orange-500 border-t py-[15px]">
                            <span>TOTAL :</span>
                            <span>{{ total }} FCFA</span>
                        </div>
                    </div>

                    <div class="flex w-full relative justify-between my-[10px] items-center">
                        <button @click="resetSelectedMenus()" class="p-[10px] px-[20px] text-sm font-bold duration-300 hover:bg-red-800 text-white rounded-[5px] bg-red-700">
                            Annuler
                        </button>
                        <button @click="toggleCommande()" :disabled="total == 0" class="p-[10px] px-[20px] text-sm font-bold duration-300 hover:bg-green-800 text-white rounded-[5px] bg-green-700">
                            Commander
                        </button>
                    </div>
                </div>

                <div v-if="openCommande" class="mx-auto w-[100%] flex justify-center items-center h-[calc(100vh-70px)] left-0 right-0 bottom-0 z-40 py-[100px] md:py-[50px] p-[20px] fixed bg-white shadow-6xl top-[70px]">
                    <div class="w-full h-full flex justify-center items-center flex-col">
                        <component :is="currentStepComponent" :currentStep="currentStep" :totalSteps="totalSteps" :nextStep="nextStep" :prevStep1="prevStep1" :prevStep2="prevStep2" />
                        
                    </div>
                    <div @click="toggleCo()" class="w-[25px] h-[25px] rounded-[50%] absolute top-[10px] cursor-pointer right-[10px] md:top-[20px] md:right-[20px] font-bold bg-black text-white flex justify-center items-center">
                        <span class="mb-[5px]">x</span>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <section id="pap1" class="w-screen hidden h-screen z-[40] justify-center items-center bg-gray-700 fixed top-0 left-0 right-0 bottom-0">
      <div class="w-[90%] h-[380px] fixed max-w-[300px] flex flex-col justify-center items-center rounded-[10px] p-[20px] top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] z-30">
          
        <div class="loader"></div>
        <div class="my-[10px] mt-[100px] text-white font-bold text-2xl text-center">
            Paiement en attente 
        </div>
      </div>
    </section>
    <section id="pap" class="w-screen hidden h-screen z-[100] bg-gray-700 fixed top-0 left-0 right-0 bottom-0">
      <div id="appa" class="w-[90%] h-[380px] fixed max-w-[300px] rounded-[10px] p-[20px] bg-white bottom-[100px] left-[50%] translate-x-[-50%] z-30 shadow-4xl">
        <div class="absolute top-[-30px] left-[50%] translate-x-[-50%] w-[60px] h-[60px] rounded-[50%] bg-white p-[5px]">
          <div class="w-full h-full relative rounded-[50%] bg-green-600 flex justify-center text-2xl text-white items-center"><i class="fa-solid fa-check"></i></div>
        </div>
        <div id="creu" class="py-[20px] relative border-b border-dashed border-gray-500 flex flex-col justify-center items-center">
          <h1 class="text-xl font-bold text-black">Thank you !</h1>
          <p class="text-xs text-gray-500">Votre commande a été éffectué avec succès</p>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-between items-center text-xs uppercase text-gray-500 ">
            <span>DATE</span>
            <span>Time</span>
          </div>
          <div class="w-full flex justify-between items-center text-sm font-bold text-black ">
            <span>{{ currentDate }}</span>
            <span>{{ currentTime }}</span>
          </div>
        </div>
        <div class="flex w-full flex-col justify-center items-center py-[10px]">
          <div class="w-full flex justify-center items-start flex-col">
            <span class="text-xs uppercase text-gray-500 ">From</span>
            <span class="text-sm font-bold text-black">{{ personData ? personData[0].nomPrenom: '' }}</span>
            <span class="text-xs font-bold text-gray-700">{{ personData ? personData[0].email: '' }}</span>
          </div>
        </div>
        <div class="flex w-full justify-between items-center py-[10px]">
          <div class="w-full flex justify-center flex-col items-start text-xs uppercase text-gray-500 ">
            <span class="text-xs uppercase text-gray-500 ">Montant</span>
            <span class="text-sm font-bold text-black">FCFA {{ total }}.00</span>
          </div>
          <div class="w-full flex justify-center flex-col items-end text-xs uppercase text-gray-500 ">
            <div class="text-xs uppercase text-gray-400 border-gray-400 border rounded-[5px] p-[5px]">
              Completé
            </div>
          </div>
        </div>
        <div class="mt-[15px] p-[10px] w-full rounded-[10px] bg-gray-100 flex justify-start items-center">
          <div class="flex justify-center items-center">
            <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center shadow-6xl items-center relative overflow-hidden">
              <img src="../assets/images/Caille/MTN Momo.png" alt="" class="w-full h-full last:">
            </div>
            <div class="w-[30px] h-[30px] rounded-[50%] flex justify-center ml-[5px] shadow-6xl items-center relative overflow-hidden">
              <img src="../assets/images/Caille/Moov Money.png" alt="" class="w-full h-full last:">
            </div>
          </div>
          <div class="mx-[10px] flex flex-col items-start justify-center">
            <span class="text-sm font-bold text-black">Mobile Money</span>
            <span class="text-xs text-gray-500 ">Envoyer par le numero {{ personData ? paiementData[0].numero: "" }}</span>
          </div>
        </div>
        <div class="absolute bottom-[-70px] left-[50%] translate-x-[-50%] w-[35px] h-[35px] rounded-[50%] bg-gray-600 p-[5px]">
          <div class="w-full h-full relative rounded-[50%] cursor-pointer flex justify-center text-2xl text-white items-center" @click="afterReservation()"><i class="fa-solid fa-xmark"></i></div>
        </div>
      </div>
    </section>
    
    <FooterComponent/>
</template>

<script setup>
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import StepOne from "@/components/StepOne.vue"
import StepTwo from "@/components/StepTwo.vue"
import StepThree from "@/components/StepThree.vue"
import { ref, onMounted, computed, watch } from 'vue';
import axios from 'axios'
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';

const pageTitle = ref("Commander en ligne - Caille d'Or")
onMounted(() => {
  document.title = pageTitle.value;
});

function afterReservation() {
    localStorage.removeItem('selectedMenus');
    localStorage.removeItem('personData');
    localStorage.removeItem('paiementData');
    router.push("/")
}

const router = useRouter()

const currentStep = ref(1);
const totalSteps = 3

const currentStepComponent = computed (() => {
    switch (currentStep.value) {
        case 1:
            return StepOne;
        case 2:
            return StepTwo;
        case 3:
            return StepThree;
        default:
            return null;
    }
});

function nextStep() {
    currentStep.value += 1;
}

function prevStep1() {
    localStorage.removeItem('personData');

    currentStep.value -= 1;
}
function prevStep2() {
    localStorage.removeItem('paiementData');
    
    currentStep.value -= 1;
}

const openCommande = ref(false)

const toggleCommande = () => {
    openCommande.value = !openCommande.value;
    localStorage.removeItem('personData');
    localStorage.removeItem('paiementData');
}

const toggleCo = () => {
    localStorage.removeItem('selectedMenus');
    localStorage.removeItem('personData');
    localStorage.removeItem('paiementData');
    openCommande.value = !openCommande.value
    currentStep.value = 1;
}

// Initialiser les données
let storedMenus = JSON.parse(localStorage.getItem('selectedMenus')) || [];

// Fonction pour mettre à jour le stockage local
const updateLocalStorage = () => {
    localStorage.setItem('selectedMenus', JSON.stringify(storedMenus));
};

// Fonction pour augmenter la quantité d'un élément
const increaseQuantity = (menu) => {
    menu.units++;
    updateLocalStorage();
    updateSummary();
};

// Fonction pour diminuer la quantité
const decreaseQuantity = (menu) => {
    if (menu.units > 0) {
        menu.units--;
        updateLocalStorage();
        updateSummary();
    }
};

const resetSelectedMenus = () => {
    localStorage.removeItem('selectedMenus');
    localStorage.removeItem('personData');
    localStorage.removeItem('paiementData');
    storedMenus.value = [];
    window.location.reload();
};

const storedCategories = ref([
    { name: 'Viandes', units: 0, total: 0 },
    { name: 'Boissons', units: 0, total: 0 },
    { name: 'Accompagnements', units: 0, total: 0 },
    { name: 'Offres Spéciales', units: 0, total: 0 }
]);

const total = ref(0);

const updateSummary = () => {
    if (!storedCategories.value) return;

    total.value = 0;
    storedCategories.value.forEach(category => {
        category.units = 0;
        category.total = 0;
    });

    storedMenus.forEach(menu => {
        const category = storedCategories.value.find(cat => cat.name === menu.category);
        if (category) {
            category.units += menu.units;
            category.total += menu.units * menu.unitPrice;
            total.value += menu.units * menu.unitPrice;
        }
    });
};

const personData = JSON.parse(localStorage.getItem('personData')) || null;
const paiementData = JSON.parse(localStorage.getItem('paiementData')) || null;

const currentDate = ref('');
const currentTime = ref('');

const errorMessage = ref('')

const getCurrentDateTime = () => {
  const now = new Date();
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  currentDate.value = now.toLocaleDateString('fr-FR', dateOptions);
  currentTime.value = now.toLocaleTimeString('fr-FR', timeOptions);
};

onMounted(() => {
    const route = useRoute();
    const isCommandeRoute = route.path === '/commande';
    getCurrentDateTime();
    updateSummary();
    if (isCommandeRoute) {
      localStorage.removeItem('personData');
      localStorage.removeItem('paiementData');
    }
});

const showNotificationPending = () => {
    document.getElementById('pap1').style.display = 'block';
    setTimeout(() => {
        document.getElementById('pap1').style.display = 'none';
        router.push("/commande")
    }, 50000);
};

const showNotificationAccepted = () => {
  document.getElementById('pap').style.display = 'block';
};

const creerCommande = async () => {
  try {
    const commandeData = {
      selectedMenus: storedMenus,
      paiementData: paiementData,
      personData: personData
    };

    const response = await axios.post('/commandes', commandeData, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    console.log('Commande créée avec succès:', response.data);
    showNotificationAccepted()
  } catch (error) {
    console.error('Erreur lors de la création de la commande:', error);
  }
};

const route = useRoute();
const { query } = route;
const { status, close } = query;

function traiterPaiement(status, close) {
  if (status === 'pending' && close === 'true') {
    showNotificationPending()
    
  } else if (status === 'approved') {

    creerCommande()

  } else {
    console.log('')
  }
}
onMounted(() => {
    const route = useRoute();
    const isCommandeRoute = route.path === '/commande/callback';
    if (isCommandeRoute) {
        traiterPaiement(status, close);
    }
});
</script>

<style scoped>
.loader {
 position: relative;
 width: 2.5em;
 height: 2.5em;
 transform: rotate(165deg);
}

.loader:before, .loader:after {
 content: "";
 position: absolute;
 top: 50%;
 left: 50%;
 display: block;
 width: 0.5em;
 height: 0.5em;
 border-radius: 0.25em;
 transform: translate(-50%, -50%);
}

.loader:before {
 animation: before8 2s infinite;
}

.loader:after {
 animation: after6 2s infinite;
}

@keyframes before8 {
 0% {
  width: 0.5em;
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }

 35% {
  width: 2.5em;
  box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
 }

 70% {
  width: 0.5em;
  box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
 }

 100% {
  box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
 }
}

@keyframes after6 {
 0% {
  height: 0.5em;
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }

 35% {
  height: 2.5em;
  box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
 }

 70% {
  height: 0.5em;
  box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
 }

 100% {
  box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
 }
}

.loader {
 position: absolute;
 top: calc(50% - 1.25em);
 left: calc(50% - 1.25em);
}
</style>