import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from "../views/Home.vue"
import Menu from '@/views/Menu.vue'
import Reservation from '@/views/Reservation.vue'
import Contact from '@/views/Contact.vue'
import Commande from '@/views/Commande.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { 
      description: "Découvrez les délices des grillades de viande de caille, poulet bicyclette et de pintade au restaurant Caille d'Or Bénin. Réservez une table en ligne ou commandez vos plats préférés pour emporter.",
      keywords: "restaurant au Bénin, grillades de viande de caille, poulet bicyclette, pintade, restaurant Caille d'Or, réservation en ligne, commande en ligne, plats à emporter",
      title: "Caille d'Or - Accueil",
      scrollToTop: true 
    }
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu,
    meta: { 
      description: "Explorez notre délicieux menu de grillades de viande de caille, poulet bicyclette et de pintade au restaurant Caille d'Or au Bénin. Découvrez une variété de plats authentiques préparés avec soin et passion.",
      keywords: "menu de grillades, viande de caille, poulet bicyclette, pintade, restaurant Caille d'Or, plats principaux, entrées, desserts, nos offres, viandes, accompagnements, boissons",
      title: "Menu - Caille d'Or",
      scrollToTop: true 
    }
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: Reservation,
    meta: { 
      description: "Réservez une table au restaurant Caille d'Or au Bénin pour une expérience culinaire inoubliable. Réservez en ligne dès maintenant et préparez-vous à savourer nos délices gastronomiques de grillades de viande de caille, poulet bicyclette et de pintade.",
      keywords: "réserver une table, réservation en ligne, restaurant Caille d'Or, expérience culinaire, grillades de viande de caille, poulet bicyclette, pintade",
      title: "Réservez une table - Caille d'Or",
      scrollToTop: true 
    }
  },
  {
    path: '/reservation/callback',
    name: 'Callback Reservation',
    component: Reservation,
    props: (route) => ({
      status: route.query.status,
      close: route.query.close
    })
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { 
      description:  "Contactez-nous pour toute question ou demande de renseignements concernant le restaurant Caille d'Or au Bénin. Nous sommes là pour vous aider à planifier votre prochaine visite et à vous faire découvrir nos spécialités de grillades.",
      keywords: "contact, restaurant Caille d'Or, informations de contact, heures d'ouverture, adresse, numéro de téléphone, grillades de viande de caille, poulet bicyclette, pintade",
      title: " Contactez-nous - Caille d'Or",
      scrollToTop: true 
    }
  },
  {
    path: '/commande',
    name: 'Commande',
    component: Commande,
    meta: { 
      description: "Commandez vos grillades de viande de caille, poulet bicyclette et de pintade préférées en ligne au restaurant Caille d'Or au Bénin. Profitez de la commodité de la commande en ligne et savourez nos délices culinaires dans le confort de votre foyer.",
      keywords: "commande en ligne, restaurant Caille d'Or, grillades de viande de caille, poulet bicyclette, pintade, commande de repas en ligne, livraison à domicile",
      title: "Commander en ligne - Caille d'Or",
      scrollToTop: true 
    }
  },
  {
    path: '/commande/callback',
    name: 'Callback',
    component: Commande,
    props: (route) => ({
      status: route.query.status,
      close: route.query.close
    })
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to: any, from: any, savedPosition: any) {
    if (to.meta.scrollToTop) {
      return { top: 0 };
    }
  },
})

router.beforeEach((to: any, from: any, next) => {
  document.title = to.meta.title || "Caille d'Or";
  
  const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta && to.meta.description) {
    descriptionMeta.setAttribute('content', to.meta.description);
  }

  const keywordsMeta = document.querySelector('meta[name="keywords"]');
  if (keywordsMeta && to.meta.keywords) {
    keywordsMeta.setAttribute('content', to.meta.keywords);
  }

  next();
});

export default router
